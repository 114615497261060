.plushie {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  height: 300px;
  max-width: 600px;
}

.menu {
  border-radius: 5px;
  padding: 5px;
  margin: 7px;
  width: 10%;
  max-width: 500px;
  min-width: 150px;
  color: #fff;
  font-size: 15px;
  background: #141414;
}

.reverse {
  position: absolute;
  right: 7px;
  top: 10px;
  width: 50px;
}

.shop {
  position: absolute;
  right: 7px;
  top: 100px;
  width: 50px;
}

.shopBackground {
  justify-content: space-around;
}

.plushieImg {
  margin: 10px;
  height: 100px;
}

.plushieImg:focus {
  border: solid;
  border-color: rgba(255, 255, 0);
  border-width: 5px;
}

.countdown {
  margin: 10px;
}

.countdownIcon {
  margin-right: 5px;
  margin-top: -2px;
}

.heartIcon {
  color: red;
}

.heartContainer {
  text-align: center;
}

.checkIcon {
  color: rgb(7, 206, 7);
}

.checkIcon:hover {
  color: rgb(13, 155, 13);
}

.trashIcon:hover {
  color: rgb(175, 15, 15);
}

.pulse {
  width: 45px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(241, 66, 66, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(241, 66, 66, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(241, 66, 66, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(241, 66, 66, 0);
  }
}
